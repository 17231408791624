<template>
  <div>
    <validation-observer ref="simpleRules">
      <spare-part-orders-item-form />
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
    </validation-observer>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import SparePartOrdersItemForm from '@/views/Spareparts/Orders/ItemForm.vue'
import AddCar from '@/views/Spareparts/Orders/Search/AddCar.vue'

export default {
  name: 'Add',
  components: {
    AddCar,
    SparePartOrdersItemForm,
    ValidationObserver,
    SaveButton,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    saveData() {
      return this.$store.getters['sparepartOrders/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/sparepart-orders/edit/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.submitStatus = false
      }
    },
  },
  created() {
    localize('tr')
    this.$store.dispatch('sparepartOrders/resetDataItem')
    this.getCustomer()
    this.getCars()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.query.id_com_customer)
    },
    getCars() {
      this.itemData.id_com_cars = this.$route.query.id_com_cars
      this.itemData.work_no = this.$route.query.Is_Emri_No
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': this.$route.query.id_com_customer,
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.itemData.submitStatus = true
          this.itemData.id_com_customer = this.$route.query.id_com_customer
          this.$store.dispatch('sparepartOrders/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
